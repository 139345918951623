body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --white: #fffbe9;
  --almost-white: #ffed92;
  --placeholder: #717f8c;
  --light-border: #adb9c6;
  --dark-blue: #005bbb;
  --blackish-blue: #080f11;
  --green: #007426;
  --crimson: #dd0065;
  --faded-yellow: #f4b07b;
  --bright-yellow: #ffd500;

  background-color: var(--white);
  color: var(--dark-blue);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

/* Classes that can be used from anywhere */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
}
